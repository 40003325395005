import request from '../utils/request'
// 商机挖掘分页查询
export function nicheList(params) {
  return request({
    url: '/businessOpportunityMining/listPage',
    method: 'get',
    params
  })
}

// 商机挖掘新增
export function nicheAdd(data) {
  return request({
    url: '/businessOpportunityMining/save',
    method: 'post',
    data
  })
}

// 商机挖掘修改
export function nicheUpdate(data) {
  return request({
    url: '/businessOpportunityMining/update',
    method: 'post',
    data
  })
}

// 商机挖掘查看详情
export function nicheListById(params) {
  return request({
    url: '/businessOpportunityMining/listById',
    method: 'get',
    params
  })
}

// 商机发布
export function nicheRelease(params) {
  return request({
    url: '/businessOpportunityMining/notIssue',
    method: 'get',
    params
  })
}

// 商机冻结
export function nicheFreeze(params) {
  return request({
    url: '/businessOpportunityMining/notFreeze',
    method: 'get',
    params
  })
}

// 标签新增 商机
export function saveLabel(data) {
  return request({
    url: '/businessOpportunityMining/saveLabel',
    method: 'post',
    data
  })
}

// 标签修改 商机
export function updateLabel(data) {
  return request({
    url: '/businessOpportunityMining/updateLabel',
    method: 'post',
    data
  })
}

// 标签详情 商机
export function listClueLabel(params) {
  return request({
    url: '/businessOpportunityMining/listClueLabel',
    method: 'get',
    params
  })
}

// 加入已选 商机匹配企业
export function businessJoinAutomation(data) {
  return request({
    url: '/businessOpportunityMiningAutomation/businessJoin',
    method: 'post',
    data
  })
}

// 设置关联度 商机匹配企业
export function correlationSet(data) {
  return request({
    url: '/businessOpportunityMiningAutomation/correlation',
    method: 'post',
    data
  })
}

// 商业挖掘自动匹配 商机匹配企业
export function listCompanyIdAutomation(params) {
  return request({
    url: '/businessOpportunityMiningAutomation/listCompanyId',
    method: 'get',
    params
  })
}

// 自动匹配查询一览 商机匹配企业
export function listPageAutomation(params) {
  return request({
    url: '/businessOpportunityMiningAutomation/listPage',
    method: 'get',
    params
  })
}

// 移除已选 商机匹配企业
export function businessDeleteByIdMatched(data) {
  return request({
    url: '/businessOpportunityMiningMatched/deleteById',
    method: 'post',
    data
  })
}

// 商业挖掘确认已选一览 商机匹配企业
export function listPageMatched(params) {
  return request({
    url: '/businessOpportunityMiningMatched/listPage',
    method: 'get',
    params
  })
}

// 发布已选 商机匹配企业
export function businessNotReleaseMatched(data) {
  return request({
    url: '/businessOpportunityMiningMatched/notRelease',
    method: 'post',
    data
  })
}

// 查询所有运营商
export function listOperatorName(params) {
  return request({
    url: '/businessOpportunityMiningAllocation/listOperatorName',
    method: 'get',
    params
  })
}

// 分配企业一览查询
export function listAllocationPage(data) {
  return request({
    url: '/businessOpportunityMiningAllocation/listAllocationPage',
    method: 'post',
    data
  })
}

// 分配企业(新增)
export function saveAllocation(data) {
  return request({
    url: '/businessOpportunityMiningAllocation/save',
    method: 'post',
    data
  })
}

//运营商列表一览
export function operatorPage(data) {
  return request({
    url: '/firstTierOperator/operatorPage',
    method: 'post',
    data
  })
}

//运营商用户列表一览
export function operatorUserPage(data) {
  return request({
    url: '/firstTierOperator/operatorUserPage',
    method: 'post',
    data
  })
}

//运营商详情
export function selectById(data) {
  return request({
    url: '/firstTierOperator/selectById',
    method: 'get',
    params: data
  })
}

//营商用户是否可登录H5
export function isHFive(data) {
  return request({
    url: '/firstTierOperator/isHFive',
    method: 'get',
    params: data
  })
}

//新增运营商用户
export function operatorUserSave(data) {
  return request({
    url: '/firstTierOperator/operatorUserSave',
    method: 'post',
    data
  })
}
//电信专区列表一览
export function getMessageInfoPage(data) {
  return request({
    url: '/firstTierOperator/getMessageInfoPage',
    method: 'post',
    data
  })
}
//电信专区详情（群发消息详情）
export function getMessageInfoDetail(data) {
  return request({
    url: '/firstTierOperator/getMessageInfoDetail',
    method: 'get',
    params: data
  })
}
//电信专区查看消息发送群列表
export function getMessageGroupPage(data) {
  return request({
    url: '/firstTierOperator/getMessageGroupPage',
    method: 'post',
    data
  })
}
//电信专区查看用户查看消息列表
export function getUserViewMessagePage(data) {
  return request({
    url: '/firstTierOperator/getUserViewMessagePage',
    method: 'post',
    data
  })
}
